import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import AboutTeam from "../components/about-team/about-team"
import TeamToggle from "../components/team-toggle/team-toggle"

function Team({ data }) {

  //Team Page Data
  const teamPage = data?.wpPage?.teampage

  //Inner Hero Data 
  const innerHero = teamPage?.teamInnerHero

  //About Team Data
  const aboutTeam = teamPage?.aboutTheTeam

  //Team Toggle Data
  const teamData = data?.allWpTeam?.nodes
  const trophyData = teamPage?.trophies

  const toggleHeading = teamPage?.teamToggleHeading

  return (
    <>
    <DefaultLayout data={data}>
    <InnerPageHero  innerData={innerHero}/>
    <AboutTeam aboutTeam={aboutTeam} />
 
    {/* <TeamToggle 
      teamData={teamData} 
      trophyData={trophyData} 
      toggleHeading={toggleHeading} 
    /> */}
    </DefaultLayout>
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpTeam (filter: {peopleCategories: {nodes: {elemMatch: {name: {nin: ["Honorary Members", "Hall of fame"]}}}}})
      {
      nodes {
        slug
        team {
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 617, width: 450)
              }
            }
          }
          number
          position
          teamCoaching
          teamName
          yearsOfCoaching
          facebookLink {
            url
          }
          instagramLink {
            url
          }
          linkedinLink {
            url
          }
          twitterLink {
            url
          }
          countryFlag {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 20, height: 10)
              }
            }
          }
        }
        title
        peopleCategories {
          nodes {
            name
          }
        }
      }
    }
    wpPage(slug: { eq: $slug }) {
      teampage {
        teamToggleHeading {
          description
          mainHeading
        }
        teamInnerHero {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, height: 380)
              }
            }
          }
          title
          leftButton {
            title
            url
          }
          rightButton {
            title
            url
          }
        }
        aboutTheTeam {
          teamName
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData (width: 136, height: 170)
              }
            }
          }
          teamInfo {
            infoHeading
            infoDetail
          }
          figure1 {
            figure1Heading
            figure1Number
          }
          figure2 {
            figure2Heading
            figure2Number
          }
          figure3 {
            figure3Heading
            figure3Number
          }
          figure4 {
            figure4Heading
            figure4Number
          }
        }
        trophies {
          trophies {
            thumbnailTrophy {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            timesWon
            titleOfAward
            yearsWon
          }
        }
      }
    }
  }
`
export default Team
