import React, { useEffect} from "react";
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./inner-page-hero.scss"
//import fontawesome
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from "@fortawesome/free-solid-svg-icons";
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"
export default function InnerPageHero({innerData}) {

    useEffect(() => {
        AOS.init({
          duration: 2000
        });
        AOS.refresh();
      }, []);

    return (
        <>
            {/* <!-- Breadcrumbs Section Start --> */}
            <div className="rs-breadcrumbs sec-color">
                <GatsbyImage
                    image={getImage(innerData?.backgroundImage?.localFile)}
                    alt={"Inner Hero Image"}
                    className="inner-img"
                />
                <div className="breadcrumbs-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center"
                             data-aos="fade-up"
                             data-aos-offset="100"
                             data-aos-delay="5"
                            >
                                <h1 className="page-title">{innerData?.title}</h1>
                                <ul>
                                    <li>
                                        <Link to={`${innerData?.leftButton?.url}`} className="active link-home">{innerData?.leftButton?.title}<FontAwesomeIcon className="text-white pl-4" icon={faLink} transform={{ rotate: 90 }} /></Link>
                                    </li>
                                    <li>{innerData?.rightButton?.title}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumbs Section End --> */}
        </>
    )
}