import React from "react"
import { useState, useEffect } from "react";
import { getImage, StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import "./about-team.scss"



export default function AboutTeam({ aboutTeam }) {
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count3, setCount3] = useState(0);
    const [count4, setCount4] = useState(0);
    useEffect(() => {
        if (count1 <= aboutTeam?.figure1?.figure1Number - 5) {
            setTimeout(() => {
                setCount1(() => (count1 + 5));
            }, []);
        }
        if (count2 <= aboutTeam?.figure2?.figure2Number - 1) {
            setTimeout(() => {
                setCount2(() => (count2 + 1));
            }, []);
        }
        if (count3 <= aboutTeam?.figure3?.figure3Number - 1) {
            setTimeout(() => {
                setCount3(() => (count3 + 1));
            }, []);
        }
        if (count4 <= aboutTeam?.figure4?.figure4Number - 1) {
            setTimeout(() => {
                setCount4(() => (count4 + 1));
            }, []);
        }
    })

    return (
        <>
            {/* <!-- Point Table Section Start --> */}
            <div className="champion-section-area-raider sec-spacer">
                <div className="container">
                    <div className="row pb-50">
                        <div className="col-lg-2 col-md-3">
                            <div className="club-sidebar-top">
                                <div className="club-logo">
                                    <GatsbyImage
                                        image={getImage(aboutTeam?.logo?.localFile)}
                                        alt={aboutTeam?.teamName}
                                        className="img-header mr-0"
                                    />
                                    <div className="club-name">
                                        <h2 className="title-bg">{aboutTeam?.teamName}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-9">
                            <div className="club-sidebar">
                                <div className="club-details">
                                    <ul className="spsoccer-team-info-list spsoccer-ul-list">
                                        {aboutTeam?.teamInfo?.map(e => {
                                            return (
                                                <li>
                                                    <strong>{e?.infoHeading}</strong>
                                                    <span>{e?.infoDetail}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="row rs-count">
                                {/* <!-- COUNTER-LIST START --> */}
                                <div className="col-lg-3 col-md-6">
                                    <div className="rs-counter-list">
                                        <h2 className="rs-counter percent">{count1}</h2>
                                        <h3>{aboutTeam?.figure1?.figure1Heading}</h3>
                                    </div>
                                </div>
                                {/*  <!-- COUNTER-LIST END --> */}

                                {/* <!-- COUNTER-LIST START --> */}
                                <div className="col-lg-3 col-md-6">
                                    <div className="rs-counter-list">
                                        <h2 className="rs-counter percent">{count2}</h2>
                                        <h3>{aboutTeam?.figure2?.figure2Heading}</h3>
                                    </div>
                                </div>
                                {/* <!-- COUNTER-LIST END --> */}

                                {/* <!-- COUNTER-LIST START --> */}
                                <div className="col-lg-3 col-md-6">
                                    <div className="rs-counter-list">
                                        <h2 className="rs-counter">{count3}</h2>
                                        <h3>{aboutTeam?.figure3?.figure3Heading}</h3>
                                    </div>
                                </div>
                                {/*  <!-- COUNTER-LIST END --> */}

                                {/*  <!-- COUNTER-LIST START --> */}
                                <div className="col-lg-3 col-md-6">
                                    <div className="rs-counter-list">
                                        <h2 className="rs-counter">{count4}</h2>
                                        <h3>{aboutTeam?.figure4?.figure4Heading}</h3>
                                    </div>
                                </div>
                                {/* <!-- COUNTER-LIST END --> */}
                            </div>
                        </div>

                    </div>
                    <div className="">
                        <hr />
                        <div className="">
                            <h3>About Saigon Raiders</h3>
                            <p>
                                Founded in 1990 by local Dane Henrik Petersen, Saigon Raiders became the first international amateur football team in Ho Chi Minh, looking for people who liked to play soccer and with the right attitude.

                                During the early years, the Raiders established a strong connection with the local community, playing against the police, immigration, customs and other divisional teams, all in the national stadium of Saigon. In the beginning, the club had 11 different nationalities now we stand at 26, and we compete both locally, domestically and internationally.
                            </p>
                            <p>   A club of tradition whose goal in 1990 has not changed much from today. We aim to be the best expat team in Saigon on and off the pitch, servicing our community and establishing connections that last. That, we believe, is why we are still here, the longest serving and the largest club with over 80 members across our teams.
                            </p>
                                <p><strong>We welcome you to join us. After all, ‘Once a Raider, Always a Raider!'</strong></p>

                                <hr />

                                <StaticImage src="../../images/team/team-photo.jpeg"></StaticImage>

                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- Point Table Section End --> */}
        </>
    )
}